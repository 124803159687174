import React from 'react'
import PropTypes from "prop-types"

import "./styles.scss"

const CremationStepsList = ({ item, isLast, index, arrowLeft, arrowRight }) => {
  let iconOrder = "order-lg-1"
  let numberOrder = "order-lg-2 mr-lg-6"
  let textOrder = ""
  let arrow = arrowLeft

  if (index % 2 === 0) {
    iconOrder = "order-lg-3"
    numberOrder = "mx-lg-6"
    textOrder = "order-lg-2 mr-lg-6"
    arrow = arrowRight
  }

  return (
    <div className={`d-flex flex-wrap flex-lg-nowrap align-items-lg-center ${index === 2 ? 'mb-4 mb-lg-10' : isLast ? 'mb-0' : 'mb-4 mb-lg-8'}`}>
      <div className={`order-3 mx-auto mb-4 mb-lg-0 mr-lg-6 ${iconOrder} d-flex w-auto justify-content-center flex-shrink-0`}>
        <img
          className={`cremation-steps-icon-${index}`}
          src={item.icon.publicURL}
          alt={item.title}
        />
      </div>
      <div className="order-2 break d-lg-none"></div>
      <div className={`order-1 mx-auto mb-4 mb-lg-0  ${numberOrder} position-relative rounded-circle d-inline-flex flex-shrink-0 bg-accent step-number align-items-center justify-content-center text-white text-center`}>
        <span className="font-bold font-size-lg-5">{index}</span>
        {!!isLast ? '' : (<img
          className={`arrow-curved arrow-curved-${index}`}
          alt="curved-arrow"
          src={arrow.publicURL}
        />)}
      </div>
      <div className="order-4 break d-lg-none"></div>
      <div className={`order-5 ${textOrder} d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start text-center text-lg-left`}>
        <h4 className="text-center text-lg-left mx-auto mx-lg-0">{item.title}</h4>
        <p>{item.description}</p>
      </div>
    </div>
  )
}

const CremationSteps = ({ arrowRight, arrowLeft, list }) => {
  return (
    <div className="col-lg-12 col-xl-10 mx-auto d-flex flex-column">
      {list.map((item, i) => {
        const isLast = i === list.length - 1;
        return <CremationStepsList key={`cremation-steps-${i}`} isLast={isLast} index={i + 1} item={item} arrowRight={arrowRight} arrowLeft={arrowLeft} />
      })}
    </div>
  )
}

CremationSteps.propTypes = {
  list: PropTypes.array.isRequired,
  arrowRight: PropTypes.object.isRequired,
  arrowLeft: PropTypes.object.isRequired,
}

export default CremationSteps
