import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PricingTable from "../../components/PricingTable"
import FreeDiscussion from "../../components/FreeDiscussion"
import ReviewTestimonials from "../../components/ReviewTestimonials"
import InfoSection from "../../components/InfoSection"
import HeroSection from "../../components/HeroSection"
import CremationSteps from "../../components/CremationSteps"
import LegalTeam from "../../components/LegalTeam"
import FeaturedCarousel from "../../components/FeaturedCarousel"

import AppDownloadBanner from "../../components/AppDownloadBanner"

const Cremation = ({ data, path }) => {
  const {
    heroSection,
    informationSection,
    directCremation,
    cremationSteps,
    goodbyeWays,
  } = data.markdownRemark.frontmatter

  // this code goes to the MD file. it says the first item in the list to be inverted. This changes the left and rights cards. Picture on left, then pic on right
  informationSection.list[0].isInverted = true

  return (
    <Layout hasSubNav={true} navId="cremation" loadMap={false}>
      <SEO
        path={path}
        title="Direct Cremation - Affordable Unattended Cremation in NSW. EstatesPlus"
        description=" We can save you thousands from the cost of a funeral with a direct cremation. It’s a cremation without a funeral service. A cremation with the utmost dignity and respect."
      />

      {/* Hero Section */}
      <HeroSection
        className="subHeader"
        id="cremation-hero"
        formButtonName="cremation-hero"
        bgImage={heroSection.bgImage}
        subTitle={heroSection.subTitle}
        buttonLinks={heroSection.buttonLinks}
      >
        <div className="d-flex flex-column mb-4">
          <h1 className="font-black">
            <span className="d-block">Arrange an </span>
            <span className="emphasize-text">Unattended Cremation</span>
            <span className="d-block">From $2,200 in NSW</span>
          </h1>
        </div>
      </HeroSection>

      <section className="bg-light">
        <ReviewTestimonials />
      </section>

      {/* video  */}
      <section className="bg-white">
        <div className="container section-gap">
          <div className="info-card row p-4 p-sm-0 p-lg-0 custom-shadow overflow-hidden">
            <div className="info-image col-12 col-xl-6 px-0 py-xl-4 pl-xl-4">
              <iframe
                className="video-general-iframe"
                src="https://www.youtube.com/embed/LRPQk7j39F4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="info-text col-12 col-xl-6 px-0 px-sm-4 pt-5 py-sm-4 p-lg-5">
              <div className="info-text-inner d-flex flex-column justify-content-center">
                <h2 className="info-title mb-4 font-bold font-size-lg-5">
                  Unattended Cremations Explained
                </h2>
                <p>
                  A direct cremation may be a suitable option for you and your
                  family. Many Australians are choosing this option over
                  traditional funerals and you can often save up around $5000.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light section-gap">
        <InfoSection infoList={[informationSection.list[0]]} noGap={true}>
          <div className="row justify-content-center mb-4">
            <div className="col-12 mb-4 d-flex justify-content-center align-items-center">
              <h2 className="font-bold d-block text-center mr-4">
                Simple & Affordable Cremations
              </h2>
            </div>
            <div className="col-12 col-md-9">
              <p className="text-center">
                You don’t need an expensive funeral service to say goodbye.
              </p>
            </div>
          </div>
        </InfoSection>
      </section>

      <section className="bg-white section-gap">
        <div className="container">
          <div className="row justify-content-center mb-6">
            <div className="col-12 mb-4 d-flex justify-content-center align-items-center">
              <h2 className="font-bold d-block text-center">
                How You Save With Direct Cremations
              </h2>
            </div>
            <div className="col-12 col-md-9">
              <p className="text-center">
                A dignified and affordable alternative to an expensive funeral.
              </p>
              <p className="text-center">
                We are able to save you thousands from the cost of a funeral
                because we have removed all the non-essentials.
              </p>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center direct-cremation-vector-container">
            <img
              className="vector-mobile"
              src={directCremation.vectorMobile.publicURL}
              alt=""
            />
            <img
              className="vector-desktop"
              src={directCremation.vector.publicURL}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="bg-white section-gap">
        <div className="container">
          <div className="row justify-content-center mb-6">
            <div className="col-12 mb-4 d-flex justify-content-center align-items-center">
              <h2 className="font-bold d-block text-center">
                Here’s How It Works
              </h2>
            </div>
            <div className="col-12 col-md-9">
              <p className="text-center">
                Simple, easy & stress-free cremation
              </p>
            </div>
          </div>
          <CremationSteps
            arrowLeft={cremationSteps.arrowLeftIcon}
            arrowRight={cremationSteps.arrowRightIcon}
            list={cremationSteps.list}
          />
        </div>
      </section>

      <section className="bg-light">
        <PricingTable tableName="cremation">
          <h2 className="text-center font-bold mb-5">
            Our <span className="emphasize-text">Pricing</span>
          </h2>
          <h3 className="text-center">
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              We aim to provide affordable pricing in NSW.
            </span>
          </h3>
        </PricingTable>
      </section>
      <AppDownloadBanner />

      <section className="bg-white">
        <FeaturedCarousel />
      </section>

      <section className="bg-white">
        <FreeDiscussion
          name="lost-a-loved-one"
          tagline="Not Sure Where To Begin?"
        >
          <p className="mb-5 text-center text-lg-left">
            Speak to us if have any questions about your loved one’s cremation
            or anything about their passing. We can help you make the right
            decisions moving forward.
          </p>
        </FreeDiscussion>
      </section>

      <section className="bg-light">
        <div className="container pt-7 pb-4">
          <div className="row justify-content-center">
            <div className="col-12 mb-4 d-flex justify-content-center align-items-center">
              <h2 className="font-bold d-block text-center">
                Ways You Can Say Goodbye
              </h2>
            </div>
            <div className="col-12 col-md-7">
              <p className="text-center">
                There are many ways to remember and celebrate your loved one’s
                life. Do what’s best for you. Here are a few ideas .
              </p>
            </div>
          </div>
        </div>
        <div className="goodbye-ways-container pt-7 pb-3">
          <div className="container">
            <div className="row flex-wrap">
              {goodbyeWays.map(way => (
                <div className="col-12 col-md-6 col-lg-4 mb-6 d-flex flex-column align-items-center text-white">
                  <div className="goodbye-ways-icon d-flex justify-content-center mb-4">
                    <img className="h-100" src={way.icon.publicURL} alt="" />
                  </div>
                  <p className="text-center font-bold">{way.title}</p>
                  <p className="text-center">{way.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <LegalTeam>
          <div className="row text-center justify-content-center mb-5">
            <div className="col-12 mb-4">
              <h2 className="font-bold">
                About
                <span className="emphasize-text m-2">Estates Plus</span>
              </h2>
            </div>
            <div className="col-12 col-md-9">
              <p className="text-center font-semibold">
                Making the process easy, stress-free and affordable.
              </p>
              <p className="text-center">
                We are team of expert lawyers and cremation coordinators, using
                technology to help Australian families with all aspects of the
                passing of a loved one.
              </p>
              <p className="text-center">We operate Australia-wide.</p>
              <p className="text-center">
                Our lawyers are registered with the appropriate legal
                regulators, so you’ll be getting a trusted service.
              </p>
              <p className="text-center">
                Estates Plus is operated and powered by the award winning law
                firm MKI Legal (which has been operating since 2012).
              </p>
            </div>
          </div>
        </LegalTeam>
      </section>
    </Layout>
  )
}

export default Cremation

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "cremation/index" } }) {
      frontmatter {
        heroSection {
          bgImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subTitle {
            content
          }
          buttonLinks {
            linkText
            linkTo
            linkStyle
            type
          }
        }
        brokenTextImage {
          publicURL
        }
        informationSection {
          list {
            title
            details
            hasImage
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        directCremation {
          vector {
            publicURL
          }
          vectorMobile {
            publicURL
          }
          bg {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        cremationSteps {
          arrowLeftIcon {
            publicURL
          }
          arrowRightIcon {
            publicURL
          }
          list {
            title
            description
            icon {
              publicURL
            }
          }
        }
        goodbyeWays {
          title
          description
          icon {
            publicURL
          }
        }
      }
    }
  }
`
